<template>
  <div>
    <div class="banner">
      <div class="banner-box d-flex" >
        <div class="title-box">
          <div class="text-box">
          </div>
        </div>
        <div class="content-box" style="width:50%; height:60vh; padding:10vh;">
          <div class="p-3" style="height:100%;background:rgba(255, 255, 255, 0.9);text-align:left">
            <p style="height:70%">
              多益專區提供多益測驗TOEIC（Test of English of International Communication）應考準備相關豐富學習資源。除了測驗簡介，更有多益必考單字、常考文法解題教學影片與線上模擬測驗試題，供學員多元練習。不僅教您如何解題，更可熟悉測驗中常見的出題情境與必備詞彙，掌握各題型應試策略。
            </p>
            <div style="text-align:center">
              <button type="button" @click="goAnchor('practice')" class="btn btn-danger btn-lg btn-sm" style="font-size:1rem">立刻進入多益模考</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="d-md-flex justify-content-between my-4 anctors">
        <div class="my-2">
          <button type="button" @click="goAnchor('introduction', -100)" class="btn btn-danger m-auto" style="width:100%">測驗簡介</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('content', -100)" class="btn btn-danger m-auto" style="width:100%">題型內容</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('situation', -100)" class="btn btn-danger m-auto" style="width:100%">測驗情境</button>
        </div>
        <div class="my-2">
          <button type="button" @click="goAnchor('guide', -100)" class="btn btn-danger m-auto" style="width:100%">能力對照</button>
        </div>
        <div class="my-2 rwd-hide">
          <button type="button" @click="goAnchor('practice')" class="btn btn-danger m-auto" style="width:100%">多益模考</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="introduction">
            <h3 class="title pr-3"> 測驗簡介</h3>
            <div class="line"></div>
          </div>
          <div>
            多益英語測驗（TOEIC®）全名為Test of English for International Communication，是美國ETS®針對母語非英語人士所研發的英語能力測驗，全球每年有超過700萬人次報考。
            多益系列測驗（The TOEIC®Program）目前可於全球160個國家施測，至少為1萬4千個以上的企業客戶、教育單位及政府機構所採用。成績不僅具備信度與效度，也具有國際流通度，足以體現考生在實際溝通情境中的英語文能力。
            TOEIC®Listening and Reading Test本身並沒有所謂的「通過」或「不通過」，而是客觀地將受測者的能力以聽力5～495分、閱讀5～495分、總分10～990分的指標呈現，受測者也可以自評現在的英語能力，進而設定學習的目標分數。
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="content">
            <h3 class="title pr-3">題型內容</h3>
            <div class="line"></div>
          </div>
          <div class="row my-3">
            <img style="width:100%" src="../../assets/image/table/from_toeic_content.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="situation">
            <h3 class="title pr-3">測驗情境</h3>
            <div class="line"></div>
          </div>
          <div class="row my-3">
            <img style="width:100%" src="../../assets/image/table/from_toeic_situation.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="guide">
            <h3 class="title pr-3">能力對照</h3>
            <div class="line"></div>
          </div>
          <div class="row my-3">
            <img style="width:100%" src="../../assets/image/table/from_toeic_fraction.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="practice">
            <h3 class="title pr-3">多益模考</h3>
            <div class="line"></div>
          </div>
          <div class="d-md-flex justify-content-between my-4">
            <div class="p-2  rwd-hide" v-for="(item, index) in exams" :key="index" @click="goPage(item.id,item.view_id)">
              <button type="button" style="width:100%" class="btn btn-dark p-4">第{{index+1}}回</button>
            </div>
            <div class="rwd-show">
              <h4>
                為提供最佳應考體驗，本站多益模擬測驗，不適用於手機介面，僅開放桌機或筆電進行考試。
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../store/api'
export default {
  name: 'Topic-raiders',
  components: {

  },
  data () {
    return {
      exams: null
    }
  },
  computed: {
  },
  mounted () {
    this.getExamData()
    this.scrollTop()
  },
  methods: {
    async getExamData () {
      const { data } = await api.get('/contents/exams?page=1&pageSize=10&type=simulation')
      this.exams = data.data
    },
    goPage (id, viewId) {
      this.$router.push({ name: 'Examination', params: { examId: id ,viewId: viewId } })
    },
    goAnchor(id, yOffset = 0) {
      const el = document.getElementById(id)
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/custom.scss";
  .banner {
    height: 60vh;
    background-image: url('../../assets/image/pic/banner_bg_toeic_top.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .title-box {
      width: 50%;
      .text-box {
        width: 50%;
        margin: auto;
        h4 {
          line-height: 200px;
          text-align: center;
          font-size: 150px;
          font-weight: 900;
          color: #fff;
          -webkit-text-stroke: 1.5px rgb(0, 0, 0);
        }
      }
    }
  }
  @media(max-width: 1080px){
    .banner {
      background-position: 0 0;
      .banner-box {
        display: none !important;
      }
    }
  }
  .container {
    text-align: left;
  }
  .anctors {
    button {
      min-width: 150px;
      font-size: 1.2rem;
    }
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .rwd-show {
    display: none;
  }
  @media screen and (max-width: 420px) {
    .rwd-hide {
      display: none !important;
    }
    .rwd-show {
      display: block;
    }
    .banner {
      height: 30vh;
      background-size: contain;
      background-image: url('../../assets/image/pic/banner_bg_toeic_top_SM_W.jpg');
    }
  }
</style>
