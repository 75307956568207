export const tw = {
  Navbar: {
    about_TOEIC: '多益攻略',
    must_know_vocabulary: '必考單字',
    Biz_talk: '真人口說',
    newsletter: '電子學習報',
    overview: '課程總覽',
    essay_revision: '寫作教室',
    email_sentence: 'E-mail 好用句',
    challenge: '單字挑戰'
  },
  Course_Overview: {
    cefr_level: 'CEFR 分級',
    A1: '（基礎級 TOEIC<225）',
    A2: '（初級 TOEIC 225-550）',
    B1: '（中級 TOEIC 550-785）',
    B2: '（中高級 TOEIC 785-945）',
    C1: '（高級 TOEIC>945）',
    C2: '（精熟級）',
    course_category: '課程類別',
    life: '生活',
    biz: '商業',
    news: '新聞',
    toeic: '多益',
    finance: '金融',
    learning_resources: '學習資源',
    unit_test: '單元測驗',
    passed: '已通過',
    unpassed: '未通過'
  },
  Login: {
    register: '我要報名',
    trial: '申請試用',
    login: '登入',
    log_out: '登出',
    start_my_course: '登入學習',
    // please_enter_your_email: '請輸入電子信箱...',
    please_enter_password: '請輸入密碼',
    forgot_Password: '忘記密碼',
    please_enter_your_email: '請輸入註冊信箱',
    send_verification_email: '發送驗證信'
  },

  Overview: {
    classify: '學前測驗',
    time: '學習總時數',
    course: '通過單元測驗課數',
    practice: '通過課後挑戰',
    exam: '完成月考數',
    exam_score: '月考分數',
    exam_advance: '任一月考>學前測驗',
    exam_advance_with_percent: '月考進步百分比',
    writing_count: '完成寫作篇數'
  },
  Home: {
    Daily_English: '每日一句',
    World_News: '全球新聞',
    Lets_play_in_English: '英文好好玩',
    FunEnglish: '跟著老師一起，玩遊戲、學單字、培養英語語感力!',
    Learning_History: '近期學習課程',
    Latest_Courses: '最新課程',
    Recommend: '推薦課程',
    Learning_Guides: '學習指引',
    Go_to_course: '前往課程',
    Play: '播放音檔',
    Keep: '收藏',
    watch_more: '看更多'
  },
  Course: {
    Description_video: '講解',
    Interactive_learning: '互動學習',
    Unit_test: '單元測驗',
    Practice: '課後挑戰',
    Record_study_time: '可累積學習時數'
  },
  HRCenter: {

  },
  Personal: {
    PersonalArea: '個人專區',
    Info: {
      Total_study_time: '學習總時數',
      Number_of_courses_passed: '通過單元測驗課數',
      Number_of_monthly_exams: '月考次數',
      First_time_login: '首次登入'
    },
    Updated_password: {
      Change_password: '修改密碼',
      Name: '姓名',
      Email: '帳號',
      Current_password: '輸入舊密碼',
      New_password: '輸入新密碼',
      Confirm_password: '密碼再確認',
      Save: '儲存',
      Company: '公司',
      Class: '班級',
      Period: '課程期間',
      Email_remind: '請輸入您的帳號',
      Change_account_remind: '帳號等同於主要信箱不可變更',
      Current_password_remind: '請輸入你的舊密碼',
      New_password_remind: '請輸入英文字母或數字的文字組合',
      Reenter_password_remind: '請再輸入一次您的新密碼'
    },
    Target: {
      Target: '學習目標',
      Goal: '目標',
      Result: '結果',
      Completed: '已完成',
      Point: '分',
      Unit: '課',
      Time: '次'
    },
    Record: {
      Learning_records: '學習紀錄',
      View_all: '總覽',
      Date_settings: '日期區間',
      Study_Time: '學習總時數',
      Number_of_completed_courses: '通過單元測驗課數',
      Number_of_review_test_passed: '通過課後挑戰數',
      Details: '詳細內容',
      Course_categories: '課程分類',
      History: '最近學習時間',
      Unit_test_score: '單元測驗分數',
      Go_to_course: '前往課程',
      Writing: '寫作紀錄'
    },
    Collection: {
      Keep: '我的收藏',
      Vocabulary: '單字',
      Lesson: '課程',
      Enter_course: '進入課程',
      Source_course: '來源課程'
    },
    Exam: {
      ExamArea: '測驗專區',
      Placement_test: '學前測驗',
      Result: '分級結果',
      Month: '考試月份',
      Exam_date: '測驗日期',
      Test_time: '使用時間',
      Listening_score: '聽力成績',
      Reading_score: '閱讀成績',
      Total_score: '總分',
      State: '狀態',
      Monthly_mock_test: '每月模考',
      More_practice: '更多練習',
      Updated_exam_date: '最近測驗日期',
      Number_of_exercises: '練習次數',
      Start: '開始',
      Details: '詳解'
      // : '',
      // : '',
      // : '',
      // : '',
      // : ''
    }
  }
}
