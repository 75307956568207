<template>
  <div class="container mx-auto" style="margin:130px 0">
    <router-view></router-view>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
export default {
  name: 'Daily',
  data () {
    return {
    }
  },
  mounted () {
    this.scrollTop ()
  },
  methods: {
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
</style>
