<template>
  <div id="interactive-player"
    class="container p-3"
    v-bind:class="{
      'small': fontSize === 's',
      'medium': fontSize === 'm',
      'large': fontSize === 'l'
    }"
    v-if="lesson && modules"
    v-on:mousemove="activeHandler()">

    <!-- Title -->
    <div id="title" class="row text-left">
      <div class="col-sm-8" v-html="lesson.name"></div>

      <div class="col-sm-4 text-right">
        <slot name="actions"></slot>
      </div>

      <p class="col-sm-12">{{ lesson.name_i18N[0].value }}</p>
    </div>

    <!-- Switcher -->
    <div ref="switcher" class="text-left pb-3 switcher">
      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'video' }"
        @click="goPage('video')"
        v-if="moduleEnable.indexOf('video') > -1 && modules.video">
        <!-- <img src="../assets/images/icon_video_on.png" v-if="page == 'video'" />
        <img src="../assets/images/icon_video_off.png" v-else /> 影片 -->
        <div>影片</div>
      </button>

      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'article' }"
        @click="goPage('article')"
        v-if="moduleEnable.indexOf('article') > -1 && !modules.video && modules.sentences">
        <!-- <img src="../assets/images/icon_book_on.png" v-if="page == 'article'" />
        <img src="../assets/images/icon_book_off.png" v-else /> 閱讀 -->
        <div>閱讀</div>
      </button>

      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'ebook' }"
        @click="goPage('ebook')"
        v-if="moduleEnable.indexOf('ebook') > -1 && modules.pages">
        <!-- <img src="../assets/images/btn_e_book_on.png" v-if="page == 'ebook'" />
        <img src="../assets/images/btn_e_book_off.png" v-else /> 課程 -->
        <div>課程</div>
      </button>

      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'text' }"
        @click="goPage('text')"
        v-if="moduleEnable.indexOf('text') > -1 && modules.sentences">
        <!-- <img src="../assets/images/icon_text_on.png" v-if="page == 'text'" />
        <img src="../assets/images/icon_text_off.png" v-else /> 文字 -->
        <div>文字</div>
      </button>

      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'reading' }"
        @click="goPage('reading')"
        v-if="moduleEnable.indexOf('reading') > -1 && modules.sentences">
        <!-- <img src="../assets/images/icon_record_on.png" v-if="page == 'reading'" />
        <img src="../assets/images/icon_record_off.png" v-else /> 跟讀 -->
        <div>跟讀</div>
      </button>

      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'detail' }"
        @click="goPage('detail')"
        v-if="moduleEnable.indexOf('detail') > -1 && modules.explAudio">
        <!-- <img src="../assets/images/icon_magic_on.png" v-if="page == 'detail'" />
        <img src="../assets/images/icon_magic_off.png" v-else /> 講解 -->
        <div>講解</div>
      </button>

      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'vocabulary' }"
        @click="goPage('vocabulary')"
        v-if="moduleEnable.indexOf('vocabulary') > -1 && modules.vocabulary">
        <!-- <img src="../assets/images/btn_e_book_on.png" v-if="page == 'vocabulary'" />
        <img src="../assets/images/btn_e_book_off.png" v-else /> 圖解單字 -->
        <div>圖解單字</div>
      </button>

      <button
        v-bind:class="{ 'btn btn-tab': true, 'active': page === 'practice' }"
        @click="goPage('practice')"
        v-if="moduleEnable.indexOf('practice') > -1 && questions && questions.length > 0">
        <!-- <img src="../assets/images/icon_quiz_on.png" v-if="page == 'practice'" />
        <img src="../assets/images/icon_quiz_off.png" v-else /> 測驗 -->
        <div>測驗</div>
      </button>
    </div>

    <!-- 影片模式 -->
    <div v-if="page === 'video'">
      <!-- <player ref="Player"
        :url="(modules.video !== null) ? modules.video : modules.audio"
        :subtitles="modules.sentences"
        v-on:video="updateVideoState" />

      <sentences
        :texts="modules.sentences"
        :currentTime="video.currentTime"
        :multiple="false"
        :lang="lang"
        :highlight="modules.vocabularies"
        v-show="false && video.cc" /> -->
        <new-player ref="texts"
          :url="(modules.video !== null) ? modules.video : modules.audio"
          :data="modules.sentences"
          :dictionary="[]"
          :vocabularies="modules.vocabularies"
          :phrases="modules.highlights"
          v-on:video="updateVideoState"
          :config="{
            lessonId: lesson.id,
            slowAudioUrl: modules.slowAudio
          }">
          <font-size-switcher slot="left" :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
        </new-player>
        <div class="mt-2">
          <font-size-switcher :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
        </div>
    </div>

    <!-- 文章模式 -->
    <div class="page" v-if="page === 'article'">
      <mini-player
        ref="MiniPlayer"
        :url="modules.audio"
        :subtitles="modules.sentences"
        v-on:video="updateVideoState" />

      <articles
        :texts="modules.sentences"
        :currentTime="video.currentTime"
        v-on:selectSentId="playSingle"
        :highlight="modules.vocabularies" />

      <font-size-switcher class="mt-2" :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
    </div>

    <!-- 圖解模式 -->
    <ebook class="page" :data="modules.pages" v-if="page === 'ebook'"/>

    <!-- 圖解單字 -->
    <div v-if="page === 'vocabulary'">
      <picture-vocabulary :data="modules.vocabulary" />
      <font-size-switcher class="mt-2" :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
    </div>

    <!-- 文字模式 -->
    <texts class="page" ref="texts"
      :url="(modules.video !== null) ? modules.video : modules.audio"
      :data="modules.sentences"
      :dictionary="[]"
      :vocabularies="modules.vocabularies"
      :phrases="modules.highlights"
      :config="{
        lessonId: lesson.id,
        slowAudioUrl: modules.slowAudio
      }"
      v-if="page === 'text'">
      <font-size-switcher class="d-flex" slot="left" :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
    </texts>

    <!-- 跟讀模式 -->
    <recorder class="page" :sectionId="sectionId" :mediaSrc="(modules.video !== null) ? modules.video : modules.audio"
      :data="modules.sentences"
      v-if="page === 'reading'">
      <font-size-switcher slot="left" :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
    </recorder>

    <!-- 詳解 -->
    <div class="page" v-if="page === 'detail'">
      <mini-player
        ref="detailPlayer"
        :url="modules.explAudio"
        v-on:video="updateVideoState"
        style="border: 1px solid #ccc; border-bottom: 0;" />

      <div class="panel">
        <sentences
          :texts="modules.sentences"
          :highlight="modules.vocabularies"
          :size="fontSize"
          :lang="['en_us', 'zh_tw'] " />
      </div>

      <div class="mt-2">
        <font-size-switcher :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
      </div>
    </div>

    <!-- 課後測驗 -->
    <practice class="page" :sectionId="sectionId" :setNoticePage="config.practice ? config.practice.noticePage : false" :quests="questions" v-if="page === 'practice'" v-on:score="handlerPracticeScore" :passRate="config.practice ? config.practice.passRate : -1">
      <font-size-switcher slot="left" :size="fontSize" v-on:size="(size) => { this.fontSize = size }" />
    </practice>
  </div>
</template>

<script>
  import Player from 'live-player/dist/Player.js'
  import NewPlayer from 'live-player/dist/NewPlayer.js';
  import Vocabularies from 'live-player/dist/Vocabularies.js'
  import MiniPlayer from 'live-player/dist/MiniPlayer.js'
  import Articles from 'live-player/dist/Articles.js'
  import Texts from 'live-player/dist/Texts.js'
  import Recorder from 'live-player/dist/Recorder.js'
  import Sentences from 'live-player/dist/Sentences.js'
  import Practice from 'live-player/dist/Practice.js'
  import FontSizeSwitcher from 'live-player/dist/FontSizeSwitcher.js'
  import Ebook from 'live-player/dist/Ebook.js'
  import PictureVocabulary from 'live-player/dist/PictureVocabulary.js'
  // import swal from 'bootstrap-sweetalert'
  // import '../../node_modules/bootstrap-sweetalert/dist/sweetalert.css'
  // import toolMixin from './mixins/toolMixin.js'
  // import langMixin from './mixins/langMixin.js'

  export default {

    name: 'LivePlayer',

    components: {
      NewPlayer,
      Player,
      Texts,
      Recorder,
      Sentences,
      Practice,
      MiniPlayer,
      Articles,
      FontSizeSwitcher,
      Ebook,
      Vocabularies,
      PictureVocabulary
    },

    props: {
      liveData: {
        type: Object,
        required: true,
        default: () => {
          return {}
        }
      },
      sectionId: {
        type: String,
        required: false,
        default: ''
      },
      folderPath: {
        type: String,
        required: false,
        default: ''
      },

      moduleEnable: {
        type: Array,
        required: false,
        default: () => {
          return ['video', 'article', 'ebook', 'text', 'reading', 'detail', 'practice', 'vocabulary']
        }
      },

      config: {
        type: Object,
        required: false,
        default: () => {
          return {
            practice: {
              noticePage: false,
              passRate: -1
            }
          }
        }
      }
    },

    // mixins: [toolMixin, langMixin],

    data () {
      return {
        video: {},
        page: '',
        isFavorite: false,
        lesson: null,
        modules: null,

        content: null,
        vocabularies: null,
        phrases: null,
        questions: null,
        fontSize: 's',
        recordObject: null,
        idleDetector: {
          enable: false,
          isIdle: false,
          timer: null,
          delay: 600000,
          isPlayerPause: false,
          idleTimer: null,
          idleTime: 0
        },
        openSideMenu: false
      }
    },

    computed: {
      lang () {
        let arr = []
        switch (this.video.cc) {
          case 3:
            arr.push('en_us')
            arr.push('zh_tw')
            break
          case 2:
            arr.push('zh_tw')
            break
          case 1:
            arr.push('en_us')
        }
        return arr
      },

      isLiveabc () {
        return window.location.hostname === 'livelearning.liveabc.com'
      }
    },

    mounted () {
      this.page = this.moduleEnable[0]
      this.init()
      this.$nextTick(() => {
        this.$refs.switcher.children[0].click()
        this.checkRecordSupport()
      })
    },

    destroyed () {
      clearInterval(this.recordObject)
      window.clearTimeout(this.idleDetector.timer)
      window.clearInterval(this.idleDetector.idleTimer)
    },

    methods: {

      init () {
        // this.jsonDataParsingRuby(this.liveData)
        // this.dataParsing(this.liveData, this.folderPath)

        let self = this
        let modules = this.liveData.modules.slice()
        this.questions = this.liveData.practices
        if (!this.questions && modules.map((el) => { return el.type }).indexOf('Practice') > -1) {
          this.questions = modules[modules.map((el) => { return el.type }).indexOf('Practice')].practices
        }

        this.lesson = this.liveData
        let module_index = modules.map((el) => { return el.type }).indexOf('Dialog') > -1 ? modules.map((el) => { return el.type}).indexOf('Dialog') : modules.map((el) => { return el.type}).indexOf('Article')
        let module_ebook_index = modules.map((el) => { return el.type}).indexOf('Ebook')
        this.modules = modules[module_index] || {}

        if (module_ebook_index > -1) {
          this.modules.pages = modules[module_ebook_index].pages
        }

        let moduleVacabularyIndex = modules.map((el) => { return el.type }).indexOf('Vocabulary')
        if (moduleVacabularyIndex > -1) {
          if (this.modules === undefined) {
            this.modules = {}
          }
          this.modules.vocabulary = modules[moduleVacabularyIndex].vocabularies
        }

        this.idleDetector.enable = (this.lesson && this.lesson.toRecord === true)
        if (this.idleDetector.enable) {
          this.$emit('learningRecord', 'add')
          window.console.log('Learning record created.')
          this.recordObject = setInterval(() => {
            self.updateRecord()
          }, 60000)
          this.activeHandler()
        } else {
          let msg = '您目前瀏覽的課程為Level ' + this.lesson.level + '，因您的學習等級與該課程不符，所以學習將不會列入學習紀錄。若有任何疑問，請參照"<a href="https://livelearning.liveabc.com/faqs/faqs.asp" target="_blank">學習紀錄的紀錄方式</a>"。'
          this.$emit('errorMessage', msg)
          window.console.log(msg)
        }
      },

      updateVideoState (data) {
        this.video = data
        this.$emit('updatedVideoState', data.state)
      },

      playSingle (sentIdx) {
        this.$refs.MiniPlayer.repeatIdx = sentIdx
        this.$refs.MiniPlayer.times = 1
        this.$refs.MiniPlayer.jumpToSent(this.$refs.MiniPlayer.subtitles[sentIdx])
        this.$refs.MiniPlayer.play()
      },

      goPage (page) {
        this.$emit('updatedVideoState', 'stop')
        this.page = page
      },

      updateRecord () {
        if (!this.idleDetector.isIdle) {
          this.$emit('learningRecord', 'update')
          window.console.log('update')
        } else {
          window.console.log('System Idle.')
        }
      },

      activeHandler () {
        if (!this.idleDetector.enable) {
          return
        }
        let self = this
        this.idleDetector.isIdle = false
        this.playerResume()
        window.clearInterval(this.idleDetector.idleTimer)
        window.clearTimeout(this.idleDetector.timer)
        this.idleDetector.timer = window.setTimeout(function () {
          self.idleDetector.isIdle = true
          swal({
            text: '請問是否還有在閱讀課程？請點選[我知道了]繼續閱讀。',
            type: 'error'
          }).then(() => {
            self.activeHandler()
          })
          self.countIdleTime()
          // holding current state
          self.playerPause()
        }, self.idleDetector.delay)
      },

      countIdleTime () {
        let self = this
        this.idleDetector.idleTime = Math.floor(Date.now() / 1000)
        this.idleDetector.idleTimer = window.setInterval(function () {
          let min = Math.floor((Math.floor(Date.now() / 1000) - self.idleDetector.idleTime) / 60)
          min >= 1 && swal({
            text: '請問是否還有在閱讀課程？你已經閒置' + min + '分鐘，請點選[我知道了]繼續閱讀。',
            type: 'error'
          }).then(() => {
            self.activeHandler()
          })
        }, 60000)
      },

      playerPause () {
        switch (this.page) {
          case 'video':
            // (this.video.state === 'playing') ? this.idleDetector.isPlayerPause = true : this.idleDetector.isPlayerPause = false
            this.idleDetector.isPlayerPause = (this.video.state === 'playing') ? true : false
            this.$refs.Player.pause()
            break
          case 'article':
            // (this.video.state === 'playing') ? this.idleDetector.isPlayerPause = true : this.idleDetector.isPlayerPause = false
            this.idleDetector.isPlayerPause = (this.video.state === 'playing') ? true : false
            this.$refs.MiniPlayer.pause()
            break
          case 'text':
            // (this.$refs.texts.video.state === 'playing') ? this.idleDetector.isPlayerPause = true : this.idleDetector.isPlayerPause = false
            this.idleDetector.isPlayerPause = (this.$refs.texts.video.state === 'playing') ? true : false
            this.$refs.texts.$refs.Player.pause()
            break
          case 'detail':
            // (this.video.state === 'playing') ? this.idleDetector.isPlayerPause = true : this.idleDetector.isPlayerPause = false
            this.idleDetector.isPlayerPause = (this.video.state === 'playing') ? true : false
            this.$refs.detailPlayer.pause()
            break
        }
      },

      playerResume () {
        if (this.idleDetector.isPlayerPause) {
          this.idleDetector.isPlayerPause = false

          switch (this.page) {
            case 'video':
              this.$refs.Player.play()
              break
            case 'article':
              this.$refs.MiniPlayer.play()
              break
            case 'text':
              this.$refs.texts.$refs.Player.play()
              break
            case 'detail':
              this.$refs.detailPlayer.play()
              break
          }
        }
      },

      openLink (link) {
        window.open(link, '_blank')
      },

      handlerPracticeScore (score) {
        this.$emit('practiceScore', score)
      },
      checkRecordSupport () {
        let index = this.moduleEnable.indexOf('reading')
        if (index > -1) {
          if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
            this.moduleEnable.splice(index, 1)
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  //@import "../assets/style/app.scss";

  #interactive-player {
    margin: auto;
    max-width: 1280px !important;
    min-width: 320px !important;

    #title {
      font-size: 16px;

      .fa-2x {
        margin-left: 0.5em;
        cursor: pointer;
      }

      .fa-heart,
      .fa-question-circle {
        color: red;
      }
    }
  }

  .btn-tab {
    img {
      padding: 0 3px;
      margin-top: -3px;
    }

    //color: $light;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);

    &:focus {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.0) !important;
    }

    &.active {
      //color: $dark;
    }
  }

  .panel {
    border: 1px #ccc solid;
    overflow-y: scroll;
    position: relative;
  }
  .theater-mode {
    // width: 80%;
  }
  .sideMenu {
    border-radius: 20px 0 0 0px;
    background-color: #fff;
    padding: 5px;
    display: none;
    border: 1px solid #ccc;
    border-right: 0px;
    position: absolute;
    z-index: 101;
  }
  .rwd-switcher {
    display: none;
  }
  @media (max-width: 680px) {
    .rwd-layout {
      margin-bottom:150px;
    }
    .sideMenu {
      display: block;
    }
    .switcher {
      padding: 10px;
      position: fixed;
      left: 0;
      width: 100%;
      bottom: 0;
      height: 78px;
      text-align: center !important;
      background-color: rgb(255, 0, 0);
      z-index: 101;
      .btn-tab {
        width: 60px;
        height: 60px;
        margin: 0 2px;
        border-radius: 50px;
        background-color: rgb(255, 104, 104);
        font-weight: bolder;
        color: #fff !important;
        img {
          display: none;
        }
      }
    }
  }
</style>
