export const cn = {
  Navbar: {
    about_TOEIC: '测验简介',
    must_know_vocabulary: '必考单字'
  },
  Overview: {
    classify: '学前测验',
    time: '学习总时数',
    course: '通过课程数',
    practice: '通过课后挑战',
    exam: '完成月考数',
    exam_score: '月考分数',
    exam_advance: '任一月考>学前测验',
    exam_advance_with_percent: '月考进步百分比',
    writing_count: '完成写作篇数'
  },
  Login: {
    register: '我要报名',
    trial: '申请试用',
    login: '登入',
    log_out: '登出',
    start_my_course: '登入学习',
    please_enter_your_email: '请输入电子信箱...',
    please_enter_password: '请输入密码...'
  },
  Home: {
    Daily_English: '每日一句',
    World_News: '全球新闻',
    Lets_play_in_English: '英文好好玩',
    FunEnglish: '跟着老师一起，玩游戏、学单字、培养英语语感力!',
    Learning_History: '近期学习课程',
    Latest_Courses: '最新课程',
    Recommend: '推荐课程',
    Learning_Guides: '学习指引',
    Go_to_course: '前往课程',
    Play: '播放音档',
    Keep: '收藏',
    watch_more: '看更多'
  },
  Course: {
    Description_video: '讲解',
    Interactive_learning: '互动学习',
    Unit_test: '单元测验'
  },
  Personal: {
    PersonalArea: '个人专区',
    Info: {
      Total_study_time: '学习时数',
      Number_of_courses_passed: '通过课数',
      Number_of_monthly_exams: '月考次数',
      First_time_login: '首次登入'
    },
    Target: {
      Target: '学习目标',
      Goal: '目标',
      Result: '结果',
      Completed: '已完成',
      Unit: '课',
      Points: '分',
      Time: '次'
    },
    Record: {
      Learning_records: '学习纪录',
      View_all: '总览',
      Date_settings: '日期区间',
      Learning_hours: '学习时数',
      Number_of_completed_courses: '完成课数',
      Number_of_unit_tests_passed: '通过课后挑战数',
      Details: '详细内容',
      Course_categories: '课程分类',
      History: '最近学习时间',
      Unit_test_score: '单元测验分数',
      Go_to_course: '前往课程',
      Writing: '写作纪录'
    },
    Collection: {
      Collection: '收藏',
      Vocabulary: '单字',
      Lesson: '课程'
    },
    Exam: {
      ExamArea: '测验专区',
      Placement_test: '学前测验',
      Result: '分级结果',
      Exam_date: '测验日期',
      Answering_time: '作答时间',
      Listening_score: '听力成绩',
      Reading_score: '阅读成绩',
      Total_score: '总分',
      Monthly_mock_test: '每月模考',
      More_practice: '更多练习',
      Updated_exam_date: '最近测验日期',
      Number_of_exercises: '练习次数',
      Start: '开始',
      Details: '详解'
    }
  }
}
