<template>
  <div>
    <div class="banner">
      <div class="banner-box d-flex" >
        <div class="title-box">
          <div class="text-box">
          </div>
        </div>
        <div class="content-box" style="width:50%; height:60vh; padding:20vh;">
          <div style="text-align:left">
            <p>
              E-mail好用句為LiveABC編輯群精心挑選商用書信最重要且實用的句子。內容涵蓋人事行政、工作業務、社交公關、貿易往來……等多面向，讓你馬上就可應用在英文書信中，一字一句讓你備妥能精準表達的商務書信能力，教你寫出專業又不失禮貌的英文E-mail。
            </p>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
// import api from '../../store/api'
export default {
  name: 'Topic-Layout',
  components: {

  },
  data () {
    return {
      // categories: null
    }
  },
  mounted () {
    this.scrollTop()
  },
  methods : {
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/custom.scss";
  .banner {
    background-image: url('../../assets/image/pic/banner_bg_emailSentence_top.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .title-box {
      width: 50%;
      .text-box {
        width: 50%;
        margin: auto;
        h4 {
          line-height: 200px;
          text-align: center;
          font-size: 150px;
          font-weight: 900;
          color: #fff;
          -webkit-text-stroke: 1.5px rgb(0, 0, 0);
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .banner {
      width: 100vw;
      // height: 40vh;
      background-size: contain;
      background-image: url('../../assets/image/pic/banner_bg_toeic_word_top_SM_W.jpg');
    }
  }
  @media(max-width: 1080px){
    .banner {
      background-position: 0 0;
      .banner-box {
        display: none !important;
      }
    }
  }
</style>
