import axios from 'axios'
import store from '../store'
import { vm } from '../main'
import Vue from 'vue'

const serverIp = 'abcdelearn.tabf.org.tw/'
const appPath = 'api'
export const apiPath = 'https://' + serverIp + appPath
const api = axios.create({
  baseURL: apiPath,
  timeout: 60000,
  headers: {
    'access-control-allow-origin': '*',
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + store.state.auth.user.token
  if (config.headers.showLoading !== false) {
    store.commit('isLoading', true)
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
  store.commit('isLoading', false)
  return response
}, function (error) {
  store.commit('isLoading', false)
  if (error.response && error.response.status === 401 && error.response.data.message !== 'PLEASE_FINISH_PRETEST') {
    console.log(error.response.data.message)
    store.commit('logout')
    vm.$router.push({ name: 'Login' })
  } else if (error.response.data) {
    store.commit('isLoading', false)
    store.commit('setErrorMessage', error.response.data.Message)
    if (error.response.data.message === 'PLEASE_FINISH_PRETEST') {
      Vue.swal({
        title: '請先完成學前測驗',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '返回'
      }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          vm.$router.push({ name: 'Exam-area' })
        }
      })
    } else if (error.response && error.response.status === 422) {
      Vue.swal({
        title: error.response.data.errors.content,
        icon: 'info',
        confirmButtonText: '返回'
      })
    } else if (error.response && error.response.data.message === 'empty input') {

    } else if (error.response && error.response.data.message === '您尚未報名課程或課程權限逾期，將導引至課程購買頁') {
      Vue.swal({
        text: error.response.data.message,
        confirmButtonText: '確定'
      }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          window.open('https://elearn.tabf.org.tw/CourseList?page=1&s=NH/hBku89h4gubtulQotRWpQ01HaPPMVLFRrnQZBnEg=')
        }
      })
    } else {
      Vue.swal({
        title: '發生錯誤',
        text: error.response.data.message,
        icon: 'error',
        confirmButtonText: '返回'
      }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          vm.$router.push({ name: 'Home' })
        }
      })
    }
  } else {
    store.commit('setErrorMessage', error.message)
  }
  return Promise.reject(error)
})

export default api
