<template>
  <div>
    <DailySentence :inputAudioData="audioData">
      <div slot="line">
        <hr style='background-color:black; height:1px; border:none;'>
      </div>
    </DailySentence>
    <pagination class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
  </div>
</template>
<script>
// @ is an alias to /src
import api from '../store/api'
import Pagination from '@/components/Pagination'
import apiLibrary from '../store/library/api'
import DailySentence from '@/components/DailySentence.vue'
export default {
  name: 'Sentence',
  data () {
    return {
      audioData: null,
      pageIndex: 1,
      pageCount: 0,
    }
  },
  mounted () {
    this.getDailySentences()
  },
  methods: {
    async getDailySentences () {
      const { data } = await apiLibrary.get(`/v1/daily-sentences?page=${this.pageIndex}`)
      this.pageCount = data.last_page
      this.pageIndex = data.current_page
      this.checkCollections(data.data)
    },
    async checkCollections (el) {
      let str = ''
      let checkArray = []
      for (const item in el) {
        str += el[item].id + ','
      }
      try {
        const { data } = await api.get(`collections/daily_sentence/check?ids=${str}`)
        checkArray = data.data
      } catch (error) {
        console.error(error.response.data)
      }
      for (const item in el) {
        const idx = checkArray.indexOf((el[item].id))
        if (idx > -1) {
          el[item].collection = true
        } else {
          el[item].collection = false
        }
      }
      this.audioData = el
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getDailySentences()
    }
  },
  components: {
    DailySentence,
    Pagination
  }
}
</script>
<style lang="scss" scoped>

</style>
