<template>
  <transition name="fade">
    <div name="fade" class="pop-up fixed-top" @click.self="close">
      <div class="pop-box container" :class="{'small-pop': size==='small'}">
        <div class="row p-2">
          <div class="col-12">
            <font-awesome-icon class="pointer" @click="close" size="2x" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
          </div>
          <div class="col-12 p-3">
            <div class="pop-edge">
              <slot name="title">
              </slot>
              <!-- <hr> -->
              <slot name="info">
              </slot>
              <!-- <hr> -->
              <slot name="vocabulary">
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Pop-up',
  data () {
    return {

    }
  },
  props: {
    size: {
      type: String,
      required: false,
      default: 'middle'
    }
  },
  methods: {
    close () {
      this.$emit('doClose', false)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/custom.scss";
  .pop-up {
    text-align: left;
    width: 100%;
    height: 100vh;
    background-color: #000000ad !important;
    z-index: 500;
    .pop-box {
      @include center();
      max-width: 1200px;
      height: 800px;
      border-radius: 20px;
      // overflow: auto;
      z-index: 100;
      background-color: rgb(255, 255, 255);
      .pop-edge {
        height: 680px;
        overflow: auto;
      }
    }
  }
  .small-pop {
    max-width: 800px !important;
    height: 250px !important;
  }
  .large-pop {
    height: 1200px !important;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  hr {
    // border: 0;
    border-bottom:  1px solid black;
  }
  @media
  (max-height: 680px) {
    .pop-box {
      max-height: 100% !important;
    }
  }
  @media (max-height: 680px) {
    .pop-edge {
      overflow: auto;
      height: 450px !important;
    }
  }
</style>
