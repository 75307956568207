<template>
  <div class="container">
    <popUp v-if="toggle" v-on:doClose="close">
      <div class="pop-title" slot="title">
        <h6>{{moment(videoData.public_at).format('YYYY/MM/DD') }}</h6>
        <h5>{{videoData.name}}</h5>
        <h6>{{videoData.name_en}}</h6>
      </div>
      <div slot="info" class="info-main" >
        <div class="video-box" v-if="dailyEnglish">
          <player style="width:100%;background:black; height:100vh" class="m-auto my-3" :url="videoPath"/>
        </div>
      </div>
    </popUp>
    <div class="row" style="min-height:80vh">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3" v-if="$route.name==='daily_english'">英文好好玩</h3>
          <h3 class="title pr-3" v-if="$route.name==='hot_word'">最新話題字</h3>
          <h3 class="title pr-3" v-if="$route.name==='travel_taiwan'">遊臺灣學英文</h3>
          <div class="line"></div>
        </div>
        <div class="row">
          <div class="col">
            <div v-for="(item, index) in dailyEnglish" :key="index" class="my-3">
              <div :key="index" class="row align-items-center content-box" style="min-height:100px">
              <div class="d-flex col-md-2">
                <div class="my-auto">
                  <img v-if="!item.is_favorite" @click="addCollections(item)" class="pointer" src="../assets/image/icon&btn/icon_have_btn_function/btn_function_mark_off.png" alt="">
                  <img v-else @click="removeCollections(item)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                </div>
                <div class="my-auto">
                  <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                    {{moment(item.public_at).format('YYYY/MM/DD') }}
                  </h5>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <div>
                  <img style="width:150px; " :src="item.cover" alt="" srcset="">
                </div>
                <div class="my-auto ml-2">
                  <p class="p-0 my-1 main-title">{{item.name}}</p>
                  <p class="p-0 my-1">{{item.name_en}}</p>
                </div>
              </div>
                <div class="col-md-3" style="text-align:right">
                  <button type="button" @click="openVideo(item)" class="btn btn-danger mx-auto px-4">More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination v-if="pageCount>1" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination'
import popUp from '@/components/PopUp.vue'
import Player from 'live-player/dist/Player'
import api from '../store/api'
export default {
  name: 'Fun_English',
  components: {
    popUp,
    Player,
    Pagination
  },
  data () {
    return {
      dailyEnglish: null,
      videoData: null,
      videoPath: null,
      toggle: false,
      pageIndex: 1,
      pageCount: 0
    }
  },
  mounted () {
    this.getEnglish()
  },
  methods: {
    close () {
      this.toggle = false
      this.videoPath = null
    },
    openVideo (el) {
      this.videoPath = el.sections[0].data.path
      this.videoData = el
      this.toggle = true
    },
    async getEnglish() {
      const params = {
        page: this.pageIndex,
        pageSize: 10,
        orderBy: 'desc',
        type: this.$route.name
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.pageCount = data.pageCount
      this.dailyEnglish = data.data
    },
    async addCollections (el) {
      const json = {
        public_at: el.public_at,
        url: el.sections[0].data.path
      }
      try {
        const fileData = new window.FormData()
        fileData.append('json', JSON.stringify(json))
        await api.post(`collections/${this.$route.name}/${el.id}`, fileData)
        this.getEnglish()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async removeCollections (el) {
      try {
        await api.delete(`collections/${this.$route.name}/${el.id}`)
        this.getEnglish()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getEnglish()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .content-box {
    // height: 100%;
    background-color: rgb(230, 230, 230);
    text-align: left;
    padding: 8px;
  }
</style>
