<template>
  <div class="container" style="margin:120px auto">
    <div class="row" v-if="recordLesson.length>0">
      <div class="col-md-12">
        <div class="d-flex my-3">
          <h3 class="title pr-3">
            {{ $t('Home.Learning_History') }}
            <img src="../assets/image/pic/icon_class_time_G.png" alt="">
            <span style="font-size:1rem; color:#999 !important;">{{ $t('Course.Record_study_time') }}</span>
          </h3>
          <div class="line"></div>
        </div>
        <keep-alive>
          <div class="row my-3">
            <div class="col-lg-3 col-md-6 col-12" v-for="(item, index) in recordLesson.slice(0,4)" :key="index">
              <div @click="goPage(item.id)">
                <div class="picture image-box pointer" :data-level="item.cefr" >
                  <img class="cover" :src="item.cover" alt="">
                </div>
                <div class="ml-2">
                  <p class="m-0" style="font-size:1.1rem">
                    {{item.name}}
                  </p>
                  <p class="m-0" style="font-size:.8rem">
                    {{item.name_en}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </keep-alive>
      </div>
    </div>
    <!-- <keep-alive>
      <div class="row" v-if="recentLessons.length>0">
        <div class="col">
          <div class="d-flex mt-3">
            <h3 class="title pr-3">{{ $t('Home.Latest_Courses') }}
              <img src="../assets/image/pic/icon_class_time_G.png" alt="">
              <span style="font-size:1rem; color:#999 !important;">{{ $t('Course.Record_study_time') }}</span>
            </h3>
            <div class="line"></div>
          </div>
          <div style="text-align:right;width:100%">
            <span class="text-danger pointer" @click="goList()">
              More
            </span>
          </div>
          <div class="row my-3">
            <div class="col-lg-3 col-md-6 col-12" v-for="(item, index) in recentLessons.slice(0,4)" :key="index">
              <div @click="goPage(item.id)">
                <div class="picture image-box pointer" :data-level="item.cefr" >
                  <img class="cover" :src="item.cover" alt="">
                </div>
                <div class="ml-2">
                  <p class="m-0" style="font-size:1.1rem">
                    {{item.name}}
                  </p>
                  <p class="m-0" style="font-size:.8rem">
                    {{item.name_en}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </keep-alive> -->
    <div class="row">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.Recommend') }}
            <img src="../assets/image/pic/icon_class_time_G.png" alt="">
            <span style="font-size:1rem; color:#999 !important;">{{ $t('Course.Record_study_time') }}</span>
          </h3>
          <div class="line"></div>
        </div>
          <div class="row my-3">
            <div class="col-lg-6 col-12" v-for="(item, index) in recommendLesson.slice(0,4)" :key="index">
            <div class="d-sm-flex my-2">
              <div style="text-align:center;" @click="goCourse(item.id)">
                <img class="m-auto pointer" style="max-width:265px" :src="item.cover" alt="">
              </div>
              <div style="border:solid 1px #ccc;" class="p-2 m-4 m-sm-0">
                <h4 class="m-0">
                  {{item.name}}
                </h4>
                <p class="m-0" style="font-size:1rem">
                  {{item.name_en}}
                </p>
                <span v-for="(el, index) in item.cefr.split(',')" :key="index" class="badge badge-danger mx-1 p-1">{{el}}</span>
                <div class="mt-3" style="height:75%; overflow: auto;">
                  <p style="height:150px">
                    {{item.description}}
                  </p>
                  <div class="p-2" style="text-align:right;">
                    <button type="button" @click="goCourse(item.id)" class="btn btn-dark">{{ $t('Home.Go_to_course') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.Learning_Guides') }}</h3>
          <div class="line"></div>
        </div>
        <div class="row my-3 align-items-stretch" style="postition: relative">
          <div class="col-md-3">
            <div style="border:solid 1px #ccc;height:100%">
              <div class="table-title" style="text-align:center">
                <h4 class="p-2" style="color:#fff">CEFR定義</h4>
              </div>
              <div class="p-2">
                <div>
                  <p style="font-size: .9rem">
                    CEFR為歐洲語言共同參考標準（Common European Framework of Reference for Languages: Learning, Teaching, Assessment，常簡稱CEFR）是歐洲委員會在2001年11月通過的一套建議標準，為歐洲語言在評量學習、教學、評量架構和教學指引、考試、教材所提供的基準，可用來評估語言學習者在所學語言的成就，並將語言程度所應具備的能力分成A1到C2六個等級，含聽、說、讀、寫、連續的表達和參與對話五項技能分級界定。CEFR受到多國政府、企業與學術機構認可，許多國際性英檢，如多益、托福、雅思皆已採用。
                  </p>
                </div>
              </div>
              <div style="text-align: right">
                <button type="button" @click="goExam()" class="btn btn-danger mx-2 mb-2">了解目前能力</button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="cefr-pic">
              <img style="width:100%;" src="../assets/image/LLPRO_CEFR_banner_bg.png" alt="">
              <img style="width:15%; position:absolute; bottom:16%; left:5%" src="../assets/image/LLPRO_CEFR_banner_woman.png" alt="">
              <img style="width:20%; position:absolute; bottom:50%; right:0" src="../assets/image/LLPRO_CEFR_banner_man.png" alt="">
              <div class="m-3 p-2 cefr-content" v-for="(item, index) in chats" :key="index">
                <div v-if="index === currentBar">
                  <div class="d-flex">
                    <div class="cefr-title mr-2 mb-3">
                      <span class="cefr-icon"> {{item.level}}</span>
                      <span class="mx-2">
                        <span>{{item.name}} {{item.subName}}</span>
                        |
                        <span class="cefr-range" v-if="item.range">{{item.range}}​</span>
                      </span>
                    </div>
                  </div>
                  <div class="mb-3">
                    <button :class="{'active':isAdvice === true}" @click="infoToggle(true)" type="button" class="btn btn-sm btn-outline-secondary mr-2">能力說明</button>
                    <button :class="{'active':isAdvice === false}" @click="infoToggle(false)" type="button" class="btn btn-sm btn-outline-secondary">學習建議</button>
                  </div>
                  <div v-if="isAdvice">
                    <div v-for="(item, index) in item.text" :key="index">
                      <p>{{index+1}}. {{item}}​</p>
                    </div>
                  </div>
                  <div v-else>
                    {{item.advice}}
                  </div>
                  </div>
                </div>
              <div class="d-flex chart">
                <div class="bar-layout" v-for="(item, index) in chats" :key="index">
                  <div class="mx-auto bar pointer d-flex justify-content-center align-items-end" @click="currentBar = index" :style="{height: item.bar}" :class="{'active': currentBar === index}">
                    <div class="bar-text">
                      {{item.level}}
                    </div>
                  </div>
                  <div class="bar-name">{{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import apiLibrary from '../store/library/api'
import api from '../store/api'

export default {
  name: 'Home',
  components: {
  },
  computed: {
    ...mapGetters([
      'authInfo',
    ])
  },
  data () {
    return {
      audioData: null,
      recentLessons: [],
      recommendLesson: [],
      recordLesson: [],
      dailyNews: null,
      dailyEnglish: null,
      channels: [],
      travelTaiwan: null,
      hotWord: null,
      exam: null,
      isAdvice: true,
      chats: [
        {
          name: '基礎級',
          subName: 'Breakthrough',
          level: 'A1',
          range: 'TOEIC: < 225',
          bar: '8%',
          text:[
            '運用所學的字母拼讀規則拼寫、讀出英文字詞與簡單語句。​​​',
            '以正確的發音及適切語速朗讀簡易對話。​​​',
            '聽懂日常生活應對中的常用語句，如自我介紹、居住地、人際關係，​ 並能作簡短的回應。​'
          ],
          advice: '建議從「生活英文」與「學習資源」中的日常實用情境會話學起，透過互動課程中的「單字」，累積字彙量，搭配「單句朗讀」、「慢速朗讀」、「重複朗讀」，聆聽英文的音節、重音及語調，同時利用「單字」中的「加入收藏」功能，將重點單字收藏至「個人專區」，建立專屬自己的單字資料庫。建議透過「課程總覽」功能，勾選CEFR分級「A1」程度課程，反覆聆聽並大聲跟著課文朗讀，熟悉課程中的字彙與單句。'
        },
        {
          name: '初級',
          subName: 'Waystage',
          level: 'A2',
          range: 'TOEIC: 225-550​',
          bar: '16%',
          text:[
            '聽懂日常生活對話或簡易廣播，並能以簡單的字詞、句子記下要點。​​​​',
            '看懂日常溝通中簡易的留言、簡訊、 賀卡等，並能以口語或書面作簡短的回應。​​​​​',
            '掌握重要的閱讀技巧如擷取大意、猜測字義、推敲文意、預測後續文意及情節發展。​'
          ],
          advice: '此階段學員已具備基本的字彙量，建議瀏覽「去旅行吧！」、「週末放輕鬆」、「日常實用情境」三門課，培養生活實用英語表達能力，另外也推薦學習「健康好食尚」「知識大百科」「名人超新星」三門課，提升閱讀文章技巧。建議透過「課程總覽」功能，勾選CEFR分級「A2」程度課程，學習廣泛各類型的影音課程與閱讀文章，提升英語的語感與表達能力，藉由互動課程中「字典」查找功能，學習更多生字，將有效擴增字彙量。'
        },
        {
          name: '中級',
          subName: 'Threshold',
          level: 'B1',
          range: 'TOEIC: 550-785​',
          bar: '24%',
          text:[
            '以正確的發音及適切的斷句、節奏、語調、語氣及速度，流暢地朗讀短文或短劇，並能以自己的話轉述談話或故事。​​​',
            '在工作時，能閱讀工作須知、公告、操作手冊、例行的文件、傳真、電報等。​​​',
            '能了解及閱讀比較不同體裁、不同主題之文章，並據以發表心得、進行評析。​'
          ],
          advice: '此階段學員已能在工作時與客戶與同事進行互動與溝通，建議點擊「金融英語」課程，透過真人模擬情境對話，學習銀行櫃台業務一定要會的英語會話，提升個人專業金融英語實力。此外，您可從「商業英語」中的「社交接待」、「團隊溝通」、「電話客服」等課程，學習如何用英語接待客戶並與客戶和團隊進行溝通。建議透過「課程總覽」功能，勾選CEFR分級「B1」程度課程，利用「跟讀」功能，進行語音辨識練習，將有助於您的發音與會話流暢度。'
        },
        {
          name: '中高級',
          subName: 'Upper Intermediate',
          level: 'B2',
          range: 'TOEIC: 785-945​',
          bar: '32%',
          text:[
            '能夠理解具體或抽象的話題，包括與自身專業領域相關的技術性研討。​​​',
            '日常生活中聽得懂演講、報導與節目；工作時，能聽懂簡報、討論、產品介紹及操作說明。',
            '能夠接待外籍人士、介紹工作內容、洽談業務、在會議中發言，以及撰寫工作報告和書信。'
          ],
          advice: '此階段學員已能理解不同主題、具體或抽象的話題，也能用英語進行會議、產品介紹與自身專業領域相關用字。建議學員持續精進「金融英語」課程相關詞彙與專業會話應用，並透過「商業英語」中的商務會議、經營管理等課程，持續增進商英溝通技巧。此外可藉由「新聞英語」CNN真人實況影音課程，提升新聞英語聽力。請點擊「課程總覽」，勾選CEFR分級「B2」程度課程，廣泛學習各類金融、新聞、商業主題，增進英語會話與閱讀實力。'
        },
        {
          name: '高級',
          subName: 'Advanced',
          level: 'C1',
          range: 'TOEIC: > 945',
          bar: '40%',
          text:[
            '能聽懂各類各類主題的談話、辯論、演講、報導及節目等。​',
            '能閱讀各類不同主題、體裁的文章，包括報章雜誌、文學作品、專業期刊、學術著作及文獻等。​',
            '對各類主題均能流暢地表達看法、參與討論，能在一般會議或專業研討會中報告或發表意見等，並能撰寫專業性報告、論文、新聞報導。​​​'
          ],
          advice: '此階段的學員除自身領域外，也能瞭解跨領域、多重領域的英文內容，用英語談論不同領域的知識。建議學員瀏覽「新聞英語」中的「生活趨勢」、「品牌專題」、「名人專訪」等主題，透過CNN各國主播新聞與專題影片，先關閉字幕，反覆聆聽後，嘗試理解通篇新聞的內容。若聽完後發現有不解之處，再打開字幕，重新播放，更能有效提昇英語聽力。建議透過「課程總覽」功能，勾選CEFR分級「C1」程度課程，藉由國際財經與各式專題報導，讓您不用出國，也能彷彿置身國外英語學習情境。'
        },
        {
          name: '精熟級',
          subName: 'Proficiency',
          level: 'C2',
          range: '',
          bar: '48%',
          text:[
            '能聽懂、讀懂各類主題及體裁的內容，理解程度與受過高等教育之母語人士相當。​​​',
            '能在各種不同場合以正確流利之英語表達看法；能適切引用文化知識、慣用語詞與專有名詞。​​​',
            '能撰寫不同性質的文章，如企劃報告、專業/學術性摘要、論文、新聞報導及時事評論等。對於各類主題均能有效完整地闡述並作深入探討。​'
          ],
          advice: '此階段學員已具備菁英級母語人士能力，相當於英語學術界專業人士的程度。建議此階段的學員持續以「新聞英語」中各類型新聞話題與專題內容，維持自身英語程度，看影片時，不開啟字幕，透過各國主播與受訪者新聞影音課程內容，精進進階英語詞彙關鍵句型，讓CNN新聞英語課程，成為您每日最佳聽力訓練教材，持續為您的頂尖英語聽力保持最佳狀態。'
        }
      ],
      currentBar: 0
    }
  },
  mounted () {
    this.scrollTop()
    if (this.authInfo.user.forcePreTest) {
      if (this.authInfo.user.isFinishPreTest) {
      }
    }
    // this.getRecentLessons()
    this.getRecordLessons()
    this.getRecommendLessons()
  },
  methods: {
    async getDailySentences () {
      const { data } = await apiLibrary.get('/v1/daily-sentences')
      // const idx = Math.floor((Math.random() * data.data.length))
      this.checkCollections([data.data[0]])
    },
    async getNews() {
      const params = {
        page: 1,
        pageSize: 1,
        orderBy: 'desc',
        type: 'daily_news'
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.dailyNews = data.data
    },
    async getEnglish() {
      const params = {
        page: 1,
        pageSize: 1,
        type: 'daily_english',
        orderBy: 'desc'
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.dailyEnglish = data.data[0]
    },
    async getHotWord() {
      const params = {
        page: 1,
        pageSize: 1,
        type: 'hot_word',
        orderBy: 'desc'
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.hotWord = data.data[0]
    },
    async getTravelTaiwan() {
      const params = {
        page: 1,
        pageSize: 1,
        type: 'travel_taiwan',
        orderBy: 'desc'
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.travelTaiwan = data.data[0]
    },
    async getRecentLessons() {
      const { data } = await api.get('contents/courses/recent')
      this.recentLessons = data.data
    },
    async getRecordLessons() {
      const params = {
        page:1,
        pageSize:4
      }
      const { data } = await api.get('/contents/courses/history', params)
      this.recordLesson = data.data
    },
    async getRecommendLessons() {
      const { data } = await api.get('contents/courses/recommend')
      // let item = data.data[Math.floor(Math.random()*data.data.length)]
      this.recommendLesson = data.data
    },
    async checkCollections (el) {
      let str = ''
      let checkArray = []
      for (const item in el) {
        str += el[item].id + ','
      }
      try {
        const { data } = await api.get(`collections/daily_sentence/check?ids=${str}`)
        checkArray = data.data
      } catch (error) {
        console.error(error.response.data)
      }
      for (const item in el) {
        const idx = checkArray.indexOf((el[item].id))
        if (idx > -1) {
          el[item].collection = true
        } else {
          el[item].collection = false
        }
      }
      this.audioData = el
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        this.$nextTick(() => {
          // this.getParents(data.data.category_id)
        })
        return data.data
      }
    },
    async getExam () {
      const { data } = await api.get('/contents/exams?page=1&pageSize=10&type=classify')
      this.exam = data.data[0]
    },
    goExam() {
      this.$router.push({ name: 'Exam-area' })
    },
    async goPage (id) {
        await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    async goList ()  {
      this.$router.push({ name: 'Search' })
    },
    async goCourse (id) {
      this.$router.push({ name: 'Course', params: { channel: id } })
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    infoToggle (el) {
      this.isAdvice = el
    },
    scrollTop () {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    async getNewsData () {
      // const { data } = await libraryApi.get(`/v1/livelibrary/Lesson/${this.view_id}`)
      const { data } = await api.get(`/contents/lesson/D9C089BD-9C01-4E43-A0E3-D7C2D571AA2B`)
      if (data.status === 'OK') {
        data.data.modules[0].vocabularies = []
        data.data.modules[0].highlights = []
        this.newsData = data.data
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";

  .container {
    text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-red;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    // width: 100%;
    width: 98%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .picture .cover:hover {
    transform: scale(1.05)  translate(-50%,-50%);
    filter:opacity(.8);
  }
  .picture {
    position:relative;
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    .cover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition:all 0.2s ease;
    }
    .tag {
      width: 40px;
      position: absolute;
      padding: 10px
    }
  }
  .table-title {
    background-color:  $main-color;
  }
  .image-box::after {
    content: attr(data-level);
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 20%;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: $main-red;
    color:white;
  }
  .cefr-pic {
    position: relative;
    .cefr-content {
      .cefr-title {
        font-size: 1.2rem;
        font-weight: bold;
        color: $main-color;
        .cefr-icon {
          color: #fff;
          background-color: red;
          padding: 2px 8px;
          border-radius: 4px;

        }
      }
      .bg-danger {
        color: #fff;
      }
      border-radius: 20px;
      width: 70%;
      position: absolute;
      top: 0;
      z-index:50 !important;
    }
    .btn:hover,
    .btn:focus {
      outline: none;
      box-shadow: none !important;
    }
    .bar-layout {
      width: 100%;
      position: relative;
      .bar-name {
        font-weight: bold;
        position: absolute;
        bottom:10px;
        text-align:center;
        width:100%;
      }
      .bar {
        border-radius: 10px 10px 0 0;
        width:50%;
        background:rgb(255, 185, 185);
        position: absolute;
        bottom: 40px;
        right: 25%;
        transition: all .5s cubic-bezier(0.42, 0, 0.58, 1.0);
        .bar-text {
          color:#fff;
        }
      }
      .active {
        background: red;
      }
    }
  }
  .subtitle {
    margin: 2%;
    width: 96%;
    line-height: 3rem;
    font-size: 1.5rem;
    box-shadow: 4px 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  .pop-title {
    h4 {
      margin: 0;
      font-size: 1rem !important;
    }
  }
  .chart {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
  }
  @media(max-width: 1280px){
    .cefr-pic {
      margin-top: 20px;
      width: 100%;
      height: 550px;
      border-radius: 10px;
      background: url('../assets/image/LLPRO_CEFR_banner_bg.png');
      background-size: cover;
      background-position: center;
      img {
        display: none;
      }
    }
    .cefr-content {
      p {
        padding: 0 20px 0 0;
      }
      width: 100% !important;
    }
    .active {
      background-color: #6c757d;
      color: #fff;
    }
    .select-box {
      border: 0px solid black !important;
      .maintitle {
        height: 10vh;
        h2 {
          position: relative;
          left: 0;
          text-align: center;
        }
      }
    }
  }
</style>
