<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="d-flex my-3" id="introduction">
            <h3 class="title pr-3">單字分類</h3>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="row align-items-center" v-for="(item, index) in categories" :key="index">
        <div class="col-sm-3 my-2">
          <div class="topic-btn" v-bind:style="{ 'background-image': 'url(' +  item.bg + ')' }">
            <p>{{item.name}}</p>
            <p>{{item.name_en}}</p>
          </div>
        </div>
        <div class="col-sm-9 my-2">
          <div class="row">
            <div class="col-4" v-for="(el, subindex) in item.submenu" :key="subindex">
              <div class="option-btn pointer" @click="goList(el)">
                <h5>{{el.name}}</h5>
                <h6>{{el.name_en}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../store/api'
export default {
  name: 'Topic-vovabularies',
  components: {

  },
  data () {
    return {
      categories: null
    }
  },
  computed: {
  },
  mounted () {
    this.getCategories()
  },
  methods: {
    async getCategories () {
      const { data } = await api.get('/contents/toeic/categories')
      this.categories = data.data
    },
    goList (el) {
      this.$router.push({ name: 'Topic-List', params: { id: el.partid, subId: el.indexno } })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/custom.scss";
  .container {
    text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .topic-btn {
    height: 100px;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    background-position: center;
    object-fit: cover;
    // background-image: url('../../assets/image/pic/btn_toeic_word_entertainment_S.jpg');
  }
  .option-btn {
    text-align: center;
    width: 100%;
    border: black solid 1px;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    h5 {
      font-size: 1rem;
      padding: 0;
      margin: 0;
    }
    h6 {
      font-size: 0.8rem;
      padding: 0;
      margin: 0;
    }
  }
  .option-btn:hover {
    box-shadow: 0px 0px 10px  rgb(109, 109, 109);
    // background-color: red;
    // color:#fff;
  }
  .submenu {
    // height: 100px;
    // height: 0px;
    overflow: hidden;
    transition: .5s;
    background-color: #fff;
    // border: 1px solid black;
    .content:hover {
      background-color: red;
      color:#fff;
    }
  }
  .close {
    opacity: 0;
    height: 0;
  }
</style>
