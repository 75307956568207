<template>
  <div class="container-fluid my-3 target" style="min-height:50vh">
    <div class="my-3" style="text-align:left" v-if="target">
      <h6 v-if="target.standard">
        <span class="title">企業標準說明：</span>
        <br>
        <span v-html="target.standard.replace(/(\r\n)|(\n)/g,'<br>')"></span>
      </h6>
    </div>
    <div class="row" v-if="target">
      <div class="col-12">
        <div class="downloadBtn">
          <button @click="downloadCertificate" v-if="certificate" type="button" class="btn btn-outline-danger">證書下載</button>
          <button v-else type="button" class="btn btn-outline-danger" disabled>證書下載</button>
        </div>
      </div>
      <div class="col-md-3" v-if="target">
        <div class="target-box my-3">
          <div class="target-title">
            {{ $t('Overview.classify') }}
          </div>
          <div class="target-content" >
            <p>
              {{ $t('Personal.Target.Goal') }}：完成學前測驗
            </p>
            <p>
              {{ $t('Personal.Target.Result') }}：{{target.classify.user ? '完成' : '未完成'}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div v-if="target.classify.user === 0" class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-else class="progress">
                  <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{target.classify.user === 0 ? '0%' : '100%'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.time.rule !== '00:00:00' && target">
        <div class="target-box my-3" v-if="target.time">
          <div class="target-title">
            {{ $t('Overview.time') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.time.rule}}
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.time.user}}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(convert(target.time.user)/convert(target.time.rule))}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(convert(target.time.user)/convert(target.time.rule))}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.course.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.course">
          <div class="target-title">
            {{ $t('Overview.course') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.course.rule}} {{ $t('Personal.Target.Unit') }}
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.course.user}} {{ $t('Personal.Target.Unit') }}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(target.course.user/target.course.rule)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(target.course.user/target.course.rule)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.exam.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.exam">
          <div class="target-title">
            {{ $t('Overview.exam') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.exam.rule}}  {{ $t('Personal.Target.Time') }}
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.exam.user}} {{ $t('Personal.Target.Time') }}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(target.exam.user/target.exam.rule)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(target.exam.user/target.exam.rule)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3"  v-if="target.exam_score.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.exam_score">
          <div class="target-title">
            {{ $t('Overview.exam_score') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.exam_score.rule}}  {{ $t('Personal.Target.Point') }}
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.exam_score.user}}  {{ $t('Personal.Target.Point') }}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(target.exam_score.user/target.exam_score.rule)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(target.exam_score.user/target.exam_score.rule)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.exam_advance.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.exam_advance">
          <div class="target-title">
            {{ $t('Overview.exam_advance') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.exam_advance.rule}}  {{ $t('Personal.Target.Point') }}
            </p>
            <p v-if="target.exam_advance">
              {{ $t('Personal.Target.Completed') }}：{{target.exam_advance.user>0?target.exam_advance.user:0}}  {{ $t('Personal.Target.Point') }}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(target.exam_advance.user/target.exam_advance.rule)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(target.exam_advance.user/target.exam_advance.rule)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.exam_advance_with_percent.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.exam_advance_with_percent">
          <div class="target-title">
            {{ $t('Overview.exam_advance_with_percent') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.exam_advance_with_percent.rule}} %
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.exam_advance_with_percent.user}} %
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(target.exam_advance_with_percent.user/target.exam_advance_with_percent.rule)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(target.exam_advance_with_percent.user/target.exam_advance_with_percent.rule)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="target.practice.rule !== 0 && target">
        <div class="target-box my-3" v-if="target.practice">
          <div class="target-title">
            {{ $t('Overview.practice') }}
          </div>
          <div class="target-content">
            <p>
              {{ $t('Personal.Target.Goal') }}：{{target.practice.rule}}  {{ $t('Personal.Target.Time') }}
            </p>
            <p>
              {{ $t('Personal.Target.Completed') }}：{{target.practice.user}}  {{ $t('Personal.Target.Time') }}
            </p>
            <div class="mx-2 d-flex" style="height: 20%;">
              <div style="width:80%">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" :style="{width: toPercent(target.practice.user/target.practice.rule)}" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div style="width:20%">
                <span style="color:red">
                  {{toPercent(target.practice.user/target.practice.rule)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
export default {
  name: 'Target',
  data () {
    return {
      certificate: false
    }
  },
  props: {
    target: {
      require: false,
      default: null
    }
  },
  mounted () {
    this.getCertificate()
  },
  methods: {
    toPercent (num) {
      // console.log(Number(num*100).toFixed(0) )
      if ( Number(num*100).toFixed(0) >= 100) {
        return '100%'
      } else {
        if (num>0) {
          return Number(num*100).toFixed(0) + '%'
        } else {
            return '0%'
        }
      }
    },
    async getCertificate () {
      const { data } = await api.get(`/certificate`)
      this.certificate = data.data.certificate

    },
    async downloadCertificate () {
      return api.post('/certificate', {} , {responseType: 'blob'}).then ((response) => {
        if (response) {
          let fileName = 'Certificate'
          const url = URL.createObjectURL(new Blob([response.data],{
            type: 'image/png'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        } else {
          return Promise.reject()
        }
      })
    },
    convert (time) {
      return time.split(':').reduce((acc,time) => (60 * acc) + + time)
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
  .title {
    font-size: 1rem;
    font-weight: bold;
  }
  .downloadBtn {
    float: right;
  }
  .downloadIcon:hover {
    color: rgb(129, 129, 129);
  }
  .target-box {
    height: 180px;
    overflow: hidden;
    background-color: #ededed;
    border: .5px solid rgb(202, 202, 202);
    border-radius: 5px;
    .target-title {
      padding: 8px;
      background-color: #0041b9;
      color: #fff;
    }
    .target-content {
      text-align: left;
      padding: 20px;
      height: 80%;
    }
    .progress {
      margin: 5px;
      height: 10px;
      background-color: #fff;
      .progress-bar {
        background-color: red;
      }
    }
  }
</style>
