<template>
  <div class="assist">
    <div class="assist-layout">
      <div class="assist-title">
        <h3>單字練習</h3>
        <h3>必備句</h3>
      </div>
      <div class="assist-box">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="(item,index) in assistData" :key="index">
            <p>{{item.name}} {{item.pronouncing}} ({{item.partOfSpeech}}) {{item.name_i18N[0].value}}</p>
            <p v-if="item.examples">
              {{item.examples[0].text}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'

export default {
  name: 'Assist',
  data () {
    return {
    }
  },
  props: {
    assistData: {

    }
  },
  methods: {
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
  .assist {
    background-color: rgb(190, 190, 190);
    height: 50vh;
    padding: 5vh 5vw;
    .assist-title {
      display: flex;
    }
    .assist-layout {
      width: 100%;
      height: 40vh;
      padding: 20px;
      text-align: left;
      border-radius: 20px;
      background-color: #fff;
      .assist-box {
        overflow: auto;
        height: 30vh;
        ul {
          list-style: none;
        }
      }
    }
  }
</style>
