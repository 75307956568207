<template>
  <div>
    <div class="banner">
      <div class="banner-box">
        <div>
          <h2>
            {{writingData.title}}
          </h2>
          <p>{{writingData.title_en}}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row my-3">
        <div class="col-12">
          <div class="row">
            <div class="d-flex my-3">
              <h3 class="title pr-3">寫作要素</h3>
              <div class="line"></div>
            </div>
          </div>
          <div class="bold-text">
            <p>寫作等級: {{writingData.cefr}}</p>
            <p>
              寫作類型: {{writingData.category}}
            </p>

            <p style="white-space: pre-line;" v-html="writingData.key_point">
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="d-flex my-3">
              <h3 class="title pr-3">前導練習</h3>
              <div class="line"></div>
            </div>
          </div>
          <div>
          </div>
          <div>
            <p class="bold-text">閱讀寫作要素後，進一步透過中文翻譯、參考答案及重點解析，嘗試將相關詞彙填入空格開始練習，以熟悉段落重點。</p>
            <div style="text-align:right">
              <button type="button" class="btn btn-outline-danger toggle" :class="{'active': translate}" @click="translate=!translate">中文翻譯</button>
              <button type="button" class="btn btn-outline-danger mx-2 toggle" :class="{'active': answer}" @click="answer=!answer">參考答案</button>
              <button type="button" class="btn btn-outline-danger toggle" :class="{'active': analysis}" @click="analysis=!analysis">重點解析</button>
            </div>
            <div v-if="translate && writingData.content_local" class="box" style="white-space: pre-line;" v-html="translateTransform(writingData.content_local)">
            </div>
            <p v-if="writingData.content" class="content mt-3" v-html="writingTransform(writingData.content)" style="white-space: pre-line;"></p>
            <p v-if="answer" class="answer">參考答案 {{writingData.answer}}</p>
            <div v-if="analysis" v-html="writingData.analysis" class="analysis box" style="white-space: pre-line;">
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row ">
            <div class="d-flex my-3">
              <h3 class="title pr-3">範例文章</h3>
              <div class="line"></div>
            </div>
          </div>
          <div>
            <p v-html="writingData.example"></p>
          </div>
        </div>
        <div class="col-12">
          <div class="row ">
            <div class="d-flex my-3">
              <h3 class="title pr-3">寫作專區</h3>
              <div class="line"></div>
            </div>
          </div>
          <div class="writing-center">
            <div class="hint">
              寫作提示: {{writingData.hint}}
            </div>
            <textarea v-if="writingData.user_status==='waiting' || writingData.user_status==='denied'" v-model="content" style="resize:none;height:300px;" class="form-control mt-4" id="validationTextarea" placeholder="建議您先於文書處理軟體內輸入寫作文章，再以複製方式將內文貼於此處。 (限180字)" required></textarea>
            <textarea v-else style="resize:none;height:300px;" disabled class="form-control mt-4" id="validationTextarea" :placeholder="writingData.user_content" required></textarea>
            <div style="text-align:center">
              <button :disabled="writingData.user_status==='approved' || writingData.user_status==='uploaded'" type="button" class="btn btn-danger mt-3" @click="doWriting">送出批改</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination.vue'
import api from '../../store/api'
export default {
  name: 'WritingEditor',
  components: {
    Pagination
  },
  computed: {

  },
  data () {
    return {
      writingData: [],
      currentId: this.$route.params.id,
      translate: true,
      answer: false,
      analysis: false,
      content: ''
    }
  },
  mounted () {
    this.getWritingData()
    this.scrollTop()
  },
  methods: {
    async getWritingData () {
      const { data } = await api.get(`/writings/${this.currentId}`)
      this.writingData = data.data
      this.content = data.data.user_content
    },
    doWriting () {
      const self = this
      self.$swal({
        title: '確認送出文章嗎?',
        text: '提醒您：課程期間同一主題限批改一次，提交後外師將排程批改。當您點選「確認」，即視為您已同意對於您所撰寫並經本公司（希伯崙股份有限公司）修改後之文章，本公司得不限地域、時間與次數，以任何形式轉載、重製、改作、散布、編輯、出版、發行、公開口述、公開播送、公開傳輸及公開展示，您將不對本公司主張著作權受到侵害。以上如同意，請點選「確認」提交文章進行批改。',
        showCancelButton: true,
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        closeOnConfirm: false
      }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          self.sendWritingData()
        }
      })
    },
    async sendWritingData () {
      const fileData = new window.FormData()
      fileData.append('id', this.currentId)
      fileData.append('content', this.content)
      await api.post(`/writings`, fileData)
      this.getWritingData()
      this.$swal({
        title: '文章已送出',
        text: '謝謝您的提交，文章已成功送出，​系統將於七個工作天派送電子郵件通知文章批改結果，​最新批改進度亦可透過寫作教室狀態進行查詢。',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    writingTransform (el) {
      let text = el.replace(new RegExp('<>', 'g'), '<input class="mx-2" size="15" style="text-align:center;;border:1px; border-bottom-style: solid;border-top-style: none;border-left-style:none;border-right-style:none;height:2rem" type="text">')
      return text
    },
    translateTransform (el) {
      let text = el.replace(new RegExp('[/^</]', 'g'), `<text style="color: #E82449">`).replace(new RegExp('[/>$/]', 'g'), `</text>`)
      return text
    },

  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/styles/color.scss";
  @import "../../assets/styles/custom.scss";
  .container {
    text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    // width: 100%;
    width: 98%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
    .banner {
    height: 250px;
    background-image: url('../../assets/image/pic/banner_bg_write_in_top.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: cover;
    .banner-box {
      padding: 60px 0 10px 0;
      h2 {
        color: #fff;
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }
      p {
        color: #fff;
        font-weight: 600;
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
  .box {
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid rgb(214, 214, 214);
    background-color: rgb(228, 228, 228);
  }
  .bold-text {
    font-weight: bold;
  }
  .content {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
  .answer {
    color: #E82449;
  }
  .analysis {
    background-color: #E8FAF5;
  }
  .writing-center {
    height: 550px;
  }
  .toggle:hover,
  .toggle:focus,
  textarea:focus {
    outline: none !important ;
    box-shadow: none !important;
  }
</style>
