<template>
  <div style="overflow: hidden;">
    <div v-if="info" class="banner" v-bind:style="{ 'background-image': 'url(' +  info.banner + ')' }">
      <div v-if="breadcrumb" class="breadcrumb">
        <div v-for="(item, key) in breadcrumb" :key="key">
          <span @click="goPage(key)" class="pointer">{{locale==='tw' ? item.name : item.name_en}} </span>
          <span v-show="breadcrumb.length -1 > key"> > </span>
        </div>
      </div>
      <div class="banner-box" >
        <div v-if="info" class="title-box">
          <h2>
            {{info.name}}
          </h2>
          <h4>
            {{info.name_en}}
          </h4>
          <p>
            {{info.description}}
          </p>
          <button v-if="practiceId !== null" type="button" @click="goCoursePage(practiceId)" class="btn btn-danger btn-sm my-2">{{$t('Course.Practice')}}
            <img v-if="practice_pass" src="../assets/image/pic/icon_complete_S_wite.png" alt="">
          </button>
        </div>
      </div>
    </div>
    <div class="container mt-2">
      <div class="row">
        <div class="col">
          <div class="d-flex my-3">
            <div class="mr-3 d-flex">
              <img style="max-height:30px" src="../assets/image/pic/icon_class_subject_commentary.png" alt="">
              <div class="my-auto">{{ $t('Course.Description_video') }}</div>
            </div>
            <div class="mr-3 d-flex">
              <img style="max-height:30px" src="../assets/image/pic/icon_class_subject_teaching.png" alt="">
              <div class="my-auto">{{ $t('Course.Interactive_learning') }}</div>
            </div>
            <div class="mr-3 d-flex">
              <img style="max-height:30px" src="../assets/image/pic/icon_class_subject_text.png" alt="">
              <div class="my-auto">{{ $t('Course.Unit_test') }}</div>
            </div>
            <div class="mr-3 d-flex">
              <img style="max-height:30px" src="../assets/image/pic/icon_class_time_G.png" alt="">
              <div class="my-auto">{{ $t('Course.Record_study_time') }}</div>
            </div>
          </div>
          <div class="row">
            <div v-for="item in lessonData" :key="item.id" class="col-lg-3 col-md-6 col-sm-12 card-box">
              <div v-if="item.type === 'digital'" class="card my-2 pointer" @click="goCoursePage(item.id)">
                <div class="picture image-box pointer">
                  <img class="tag" v-if="!item.is_favorite" src="../assets/image/pic/btn_tag_off.png" alt="" @click.stop="addCollections(item)">
                  <img class="tag" v-else src="../assets/image/pic/btn_tag_on.png" alt="" @click.stop="removeCollections(item)">
                  <img :src="item.cover" class="card-img-top cover">
                </div>
                <div class="card-body" style="background:	#F5F5F5">
                  <div class="card-title" style="height:110px">
                    <div class="content">
                      <h5>{{item.name}}</h5>
                    </div>
                    <div class="content">
                      <p>{{item.name_en}}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-stretch">
                    <div class="my-auto">
                      <span class="badge badge-danger">{{item.cefr}}</span>
                      <img v-if="item.has_detail_video" src="../assets/image/pic/icon_class_subject_commentary.png" alt="">
                      <img v-if="item.has_live_course" src="../assets/image/pic/icon_class_subject_teaching.png" alt="">
                      <img v-if="item.has_practice" src="../assets/image/pic/icon_class_subject_text.png" alt="">
                      <img src="../assets/image/pic/icon_class_time_G.png" alt="">
                    </div>
                    <img v-if="item.is_pass" class="my-auto" style="width:25px; height:25px" src="../assets/image/pic/icon_class_video_complete.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '../store/api'
import Player from 'live-player/dist/Player'

export default {
  name: 'Course',
  data () {
    return {
      locale: window.localStorage.getItem('locale') || 'tw',
      lessonData: null,
      info: null,
      breadcrumb: [],
      practiceId: null
    }
  },
  components: {
    // VideoList,
    Player
  },
  watch: {
  },
  mounted () {
    this.getLessonInfo()
    this.scrollTop()
  },
  computed : {
    practice_pass () {
      return this.lessonData[this.lessonData.length-1].is_pass
    }
  },
  methods: {
    async getLessonInfo () {
      const { data } = await api.get(`/contents/package_courses/${this.$route.params.channel}`)
      if (data.status === 'Success') {
        this.getParents(data.data.category_id)
        this.info = data.data
        const idx = data.data.courses.map(x => x.type).indexOf('practice')
        if (idx > -1) {
          this.practiceId = data.data.courses[idx].id
          data.data.courses.splice(idx,1)
        }
        this.lessonData = data.data.courses
      }
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    async getParents (id) {
      const { data } = await api.get(`/contents/channels/${id}`)
      const arr = [data.data, this.info.parent, this.info]
      this.breadcrumb = arr
    },
    async addCollections (el) {
      await this.getLessonId(el.id).then((val) => {
        try {
          const json = {
            banner: val.package_course.banner,
            view_id: val.sections[0].data.view_id||val.sections[0].id
          }
          const fileData = new window.FormData()
          fileData.append('json', JSON.stringify(json))
          fileData.append('view_id', val.sections[0].data.view_id||val.sections[0].id)
          api.post(`collections/course/${el.id}`, fileData).then((res)=> {
            if (res.status === 200) {
              this.getLessonInfo()
            }
          })
        } catch (error) {
          console.error(error.response.data)
        }
      })
    },
    async removeCollections (el) {
      await this.getLessonId(el.id).then((val) => {
        try {
          api.delete(`collections/course/${el.id}`).then((res)=> {
            if (res.status === 200) {
              this.getLessonInfo()
            }
          })
        } catch (error) {
          console.error(error.response.data)
        }
      })
    },
    async goCoursePage (id) {
      await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    goPage (idx) {
      // console.log(idx)
      if (idx === 0) {
        this.$router.push({ name: 'Home'})
      } else if (idx === 1) {
        this.$router.push({ name: 'Channels', params: { channel: this.breadcrumb[idx].id } })
      }
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .container {
    // text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .title-box {
    h2,h4 {
      font-weight: bolder;
      color: #fff;
    }
    p {
      width: 80%;
      margin: auto;
      color: #fff;
    }
  }
  .banner {
    height: 250px;
    background-image: url('../assets/image/pic/banner_bg_meeting_in_top.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: cover;
    .breadcrumb {
      color: #ffff;
      background-color: transparent;
    }
  }
  .picture {
    position:relative;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    background-color: #000;
    .cover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 130%;
      height: 130%;
      object-fit: contain;
      transition:all 0.2s ease;
    }
    .tag {
      width: 20px;
      position: absolute;
      top: 5%;
      left: 5%;
      z-index: 50;
    }

  }
  .picture .cover:hover {
    transform: scale(1.05)  translate(-50%,-50%);
    filter:opacity(.8);
  }
  .course-info {
    text-align: left;
    // padding: 20px 50px;
  }
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $gray-color;
    div {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: white;
    }
  }
  .card-title {
    border-bottom: 1px solid #bbb;
    text-align: left;
    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    h5 {
      font-size: 1.2rem;
      font-weight: bolder;
    }
    p {
      font-size: .8rem;
    }
  }
  @media screen and (max-width: 601px) {
    .title-box {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

</style>
