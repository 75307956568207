<template>
  <div class="container">
    <popUp v-if="toggle" v-on:doClose="close">
      <div class="pop-title" slot="title">
        <h4>{{moment(createdTime).format('YYYY/MM/DD') }}</h4>
      </div>
      <div slot="info" class="info-main" >
        <div class="video-box">
          <live-player ref="liveplayer" :moduleEnable="['video', 'article', 'ebook', 'text']" :liveData="newsData" v-if="newsData.modules.length > 0 " />
        </div>
      </div>
    </popUp>
    <div class="row">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.World_News') }}</h3>
          <div class="line"></div>
        </div>
        <div class="row">
          <div class="col">
            <div v-for="(item, index) in dailyNews" :key="index" class="my-3">
              <div :key="index" class="row align-items-center content-box" style="min-height:100px">
              <div class="d-flex col-md-2">
                <div class="my-auto">
                  <img v-if="!item.is_favorite" @click="addCollections(item)" class="pointer" src="../assets/image/icon&btn/icon_have_btn_function/btn_function_mark_off.png" alt="">
                  <img v-else @click="removeCollections(item)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                </div>
                <div class="my-auto">
                  <h5 class="p-0 my-0 mx-2" style="font-weight: bolder;">
                    {{moment(item.public_at).format('YYYY/MM/DD') }}
                  </h5>
                </div>
              </div>
              <div class="col-md-7 d-flex">
                <img style="width:150px" :src="item.cover" alt="">
                <div class="my-auto mx-2">
                  <p class="p-0 my-1 main-title">{{item.name}}</p>
                  <p class="p-0 my-1">{{item.name_en}}</p>
                </div>
              </div>
                <div class="col-md-3" style="text-align:right">
                  <button type="button" @click="getNewsData(item.sections[0].data.view_id, index)" class="btn btn-danger mx-auto px-4">More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination v-if="pageCount>1" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination'
import popUp from '@/components/PopUp.vue'
// import DailyNews from '@/components/DailyNews.vue'
import api from '../store/api'
import LivePlayer from 'live-player'
// import libraryApi from '../store/library/api'
export default {
  name: 'News',
  components: {
    popUp,
    LivePlayer,
    // DailyNews,
    Pagination
  },
  data () {
    return {
      toggle: false,
      createdTime: '',
      newsData: null,
      dailyNews: null,
      pageIndex: 1,
      pageCount: 0
    }
  },
  mounted () {
    this.getNews()
  },
  methods: {
    async getNews() {
      const params = {
        page: this.pageIndex,
        pageSize: 10,
        orderBy: 'desc',
        type: 'daily_news'
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.pageCount = data.pageCount
      this.dailyNews = data.data
    },
    async getNewsData (id ,idx) {
      // const { data } = await libraryApi.get(`/v1/livelibrary/Lesson/${id}`)
      const { data } = await api.get(`/contents/lesson/${id}`)
      if (data.status === 'OK') {
        this.createdTime = this.dailyNews[idx].created_at
        data.data.modules[0].vocabularies = []
        data.data.modules[0].highlights = []
        this.newsData = data.data
        this.toggle = true
      }
    },
    async addCollections (el) {
      console.log(el)
      const json = {
        view_id: el.sections[0].data.view_id,
        public_at: el.public_at
      }
      try {
        const fileData = new window.FormData()
        fileData.append('json', JSON.stringify(json))
        await api.post(`collections/daily_news/${el.id}`, fileData)
        this.getNews()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    async removeCollections (el) {
      try {
        await api.delete(`collections/daily_news/${el.id}`)
        this.getNews()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    close (close) {
      this.toggle = close
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getNews()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    width: 100%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .pop-title {
    text-align: left;
  }
  .main-title {
    font-size:1.2rem;
    font-weight: bolder;
  }
  .content-box {
    // height: 100%;
    background-color: rgb(230, 230, 230);
    text-align: left;
    padding: 8px;
  }
</style>
