<template>
  <div>
    <div>
      <iframe class="layout" src="https://voccard.liveabc.com/learningpro_toeic/" frameborder="0">
    </iframe>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Challenge',
  components: {
  },
  computed: {
    ...mapGetters([
      'authInfo',
    ])
  },
  data () {
    return {
    }
  },
  mounted () {
    if (!this.authInfo.authorized) {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";
  @import "../assets/styles/custom.scss";
  .layout {
    width: 90%;
    height: 100vh;
    padding: 5vh 0;
  }
</style>
